<template>
  <div class="overlay">
    <page-title>{{i18n.MENU.shape}}</page-title>
    <div class="shape">
      <div class="shape__empty"
           v-if="items.length === 0">
        {{i18n.SHAPE.EMPTY_SHAPES}}
      </div>
      <div :class="[
        'shape__item',
        {'shape__item--current': item.id === shape.id},
        {'shape__item--active': item.active === '1'}
        ]"
           @click.prevent="setShape(item, item.id === shape.id || item.active === '0')"
           v-for="(item, idx) in items"
           :key="`shape__${idx}`">
        <img :src="item.image_svg"
             :alt="item.title">
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/page-title/page-title.vue'
import axios from 'axios'
import {mapGetters} from 'vuex'
import {API} from '@/constants/api'
import {setCode} from '@/utils/code'

export default {
  data() {
    return {
      items: [],
    }
  },
  computed: {
    ...mapGetters('config', [
      'shape',
      'model',
      'glass',
      'color',
      'furniture',
      'options',
      'inox',
      'view',
      'mirrored',
      'black',
    ]),
    ...mapGetters('session', [
      'i18n',
    ]),
  },
  components: {
    PageTitle,
  },
  metaInfo() {
    return {
      title: this.i18n.MENU.shape,
      meta: [
        {vmid: 'description', property: 'description', content: 'Форма дверей'},
        {vmid: 'og:title', property: 'og:title', content: 'Форма дверей'},
        {vmid: 'og:description', property: 'og:description', content: 'Форма дверей'},
      ],
    }
  },
  methods: {
    async setShape(shape, isActive) {
      if (isActive) {
        return false
      }
      let response;
      const {
        model,
        glass,
        color,
        furniture,
        options,
        inox,
        view,
        mirrored,
        black,
      } = this
      const moldingView = inox?.url.includes('molding') ? inox?.url.split('-')[1] : null

      try {
        response = await axios.get(API.GET_CODE_FROM_CONFIG, {
          params: {
            shape: shape.id,
            model: model.id,
            glass: glass.id,
            colour_outside: color.outside.id,
            colour_inside: color.inside.id,
            furniture: furniture.id,
            options: options.id,
            inox: inox.id,
            molding: moldingView,
            view: view.id,
            mirrored: mirrored.id,
            black,
          },
        })
      } catch (e) {
        console.error(e)
      }
      await setCode({code: response.data.code, store: this.$store, router: this.$router})
      await this.$store.dispatch('config/CONFIG_SET_SHAPE', shape)
    },
  },
  created() {
    axios
      .get(API.GET_SHAPE)
      .then(response => (this.items = response.data));
  }
}
</script>

<style lang="scss">
.shape {
  display: flex;
  flex-wrap: nowrap;
  max-width: 100vw;
  overflow-x: auto;
  @include miw($xl) {
    max-width: inherit;
    flex-wrap: wrap;
    overflow-x: visible;
  }

  &__empty {
    color: #ffffff;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 50vw;
    flex: 1 0 50vw;
    height: calc(50vh - 25px - 40px);
    opacity: .5;
    border: solid #5C646B {
      width: 0 1px 1px 0;
    }
    transition: background-color .25s linear;
    &--active {
      opacity: 1;
      cursor: pointer;
    }
    @include miw($xl) {
      flex: 1 0 50%;
      max-width: 50%;
      height: 130px;
      &--active:hover {
        background: #C70552;
      }
    }

    &--current {
      background: #C70552;
      cursor: default;
    }
  }
}
</style>
